// src/components/StyledComponents.js

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

export const Root = styled(Paper)({
  padding: '20px',
  margin: '20px',
  maxWidth: '600px',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '#f9f9f9',
});

export const Section = styled(Box)({
  marginBottom: '20px',
});

export const FormControlStyled = styled(FormControl)({
  marginBottom: '20px',
  minWidth: 120,
});

export const ButtonStyled = styled(Button)({
  marginTop: '20px',
});
