import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { API_URL } from "../apiConstants";
import Header from "../Header";
import { Link } from "react-router-dom";
import axios from "axios";

const AdminDashboard = () => {
  // const { user, logout } = useContext(AuthContext);
  const { auth } = useContext(AuthContext);

  const [clients, setClients] = useState([]);

  useEffect(() =>{
    axios
      .get(`${API_URL}/client/details`, {
        headers: { Authorization: auth },
      })
      .then((response) => {
        if (response.status === 200) {
          setClients(response.data.result);
          // setLoading(false);
          // setTmpData(data);
          // // console.log(tmpData)
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  },[])

  return (
    <>
      <Header />
      <section class="pcoded-main-container">
        <div class="pcoded-wrapper">
          <div class="pcoded-content">
            <div class="pcoded-inner-content">
              {/* <!-- [ breadcrumb ] start --> */}
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Dashboard </h5>
                      </div>
                      {/* <ul class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="">
                            <i class="feather icon-home"></i>
                          </Link>
                        </li>
                        <li class="breadcrumb-item">
                          <Link to="">Admin Profile</Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- [ Hover-table ] start --> */}
              <div class="col-xl-12">
                <div class="card">
                  <div class="card">
                    {/* <div class="card-block border-bottom">
                      <div class="row d-flex align-items-center">
                        <div class="col-auto">
                          <i class="feather icon-zap f-30 text-c-green"></i>
                        </div>
                        <div class="col">
                          <h3 class="f-w-300">235</h3>
                          <span class="d-block text-uppercase">
                            TOTAL IDEAS
                          </span>
                        </div>
                      </div>
                    </div> */}
                    
                    <div class="card-block">
                      <div class="row d-flex align-items-center">
                        <div class="col-auto">
                          <i class="feather icon-map-pin f-30 text-c-blue"></i>
                        </div>
                        <div class="col">
                          <h3 class="f-w-300">{clients.length}</h3>
                          <span class="d-block text-uppercase">
                            TOTAL CLIENTS
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="card-header">
                    <h5>Search Order</h5>
                    <span class="d-block m-t-5">
                      search order by patient name
                    </span>
                    <div>
                      <div class="main-search">
                        <div class="input-group">
                          <input
                            type="text"
                            id="m-search"
                            class="form-control"
                            placeholder="Search . . ."
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* Modal for the form */}
                  {/* <Modal
                    isOpen={resultModal}
                    onRequestClose={() => setResultModal(false)}
                    ariaHideApp={false}
                    style={{
                      content: {
                        position: "absolute",
                        top: "15%",
                        left: "20%",
                        right: "10%",
                        bottom: "1%",
                      },
                    }}
                  >
                    <ResultUpdate
                      testId={testId}
                      auth={auth}
                      // patientId={location.state.pid}
                    />
                  </Modal> */}
                  <div class="card-block table-border-style">
                    {/* <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Date of Birth</th>
                            <th>Test Id</th>
                            <th>Order</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>{orderLists}</tbody>
                      </table>
                    </div> */}
                    {/* pagination start */}
                    {/* <Pagination
                      nPages={nPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    /> */}
                    {/* pagination stop */}
                  </div>
                </div>
              </div>
              {/* <!-- [ Hover-table ] end --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminDashboard;
