import React, { Suspense, lazy, useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../Header";
import { useLocation } from 'react-router-dom';

// Lazy load the components for each tab's content
const ClientInfo = lazy(() => import("./ClientInfo"));
const UserList = lazy(() => import("./UserList"));
const TestList = lazy(() => import("./TestList"));
const ProfileList = lazy(() => import("./ProfileList"));
const FilesList = lazy(() => import("./FilesList"));

export default function Client(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [id, setId] = useState("");


  // Access the data from the location's state
  const location = useLocation();
  // const id = location.state.pid;
  const fetchData = async () => {
    setId(location.state.id)
  }
  useEffect(() => {
    
    fetchData();
    // reload page
  }, []);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Header />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">
                            <i className="feather icon-home"></i>
                          </Link>
                        </li>

                        <li className="breadcrumb-item">
                          <Link to="/client">Client</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="#">Client Info</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Client Information</h5>
                        </div>

                        <Box>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <Tabs
                                  value={activeTab}
                                  onChange={handleTabChange}
                                >
                                  <Tab label="Client Info" />
                                  <Tab label="Users" />
                                  <Tab label="Test" />
                                  <Tab label="Profile" />
                                  <Tab label="Files" />
                                </Tabs>
                              </div>
                            </div>
                          </div>
                          <Suspense fallback={<div>Loading...</div>}>
                            {activeTab === 0 && <ClientInfo id={location.state.id} />}
                            {activeTab === 1 && <UserList id={id} />}
                            {activeTab === 2 && <TestList id={id} />}
                            {activeTab === 3 && <ProfileList id={id} />}
                            {activeTab === 4 && <FilesList id={id} />}
                          </Suspense>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

