import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Pagination from "../Pagination";
import { API_URL } from "../apiConstants";
import { Link } from "react-router-dom";
import moment from "moment";
import generateFile from "../FileGenerator";
import Modal from "react-modal";
import Header from "../Header";
import { AuthContext } from "../../context/AuthContext";
import OrderInfo from "./OrderInfo";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

function BillerDashboard() {
  const { user, auth } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [tmpData, setTmpData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [billerModal, setBillerModal] = useState(false);
  const [testId, setTestId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(25);


  const fetchOrdersDetails = ()=> {
    axios
    .get(`${API_URL}/orders/orderlist/${user.clinic}`, {
      headers: { Authorization: auth },
    })
    .then((response) => {
      // Filter only the records whose status is not null
      const filteredRecords = response.data.result.filter(
        (record) => record.status != null
      );
      // console.log(filteredRecords)
      setData(filteredRecords);
      setLoading(false);
      setTmpData(data);
    })
    .catch((error) => {
      // console.log(error);
    });
  }

  useEffect(() => {
    //  fetch data from get user api
    fetchOrdersDetails()
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = searchQuery
    ? tmpData.slice(indexOfFirstRecord, indexOfLastRecord)
    : data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = searchQuery
    ? Math.ceil(tmpData.length / recordsPerPage)
    : Math.ceil(data.length / recordsPerPage);
  //

  const orderLists = (currentRecords || []).map((item) => (
    <>
      {/* {item.biller && ( */}
      {item.status && (
        <tr>
          <td>{item.firstname} </td>
          <td>{item.lastname} </td>
          <td>{item.biller === "Other" ? item.billername : item.biller}</td>
          
          <td>{item.test_id} </td>
          
          <td>
            <Link
              className="feather icon-file-text"
              onClick={() => {
                generateFile("results", item.test_id, {
                  Authorization: auth,
                });
              }}
              style={{ fontSize: "16px" }}
            ></Link>
           
          </td>

          <td>{item.status}</td>
          <td>
            <Link
              onClick={() => {
                setBillerModal(true);
                setTestId(item.test_id);
              }}
            >
              <i className="feather icon-chevrons-right"></i>
            </Link>
          </td>
        </tr>
      )}
    </>
  ));

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();

    setSearchQuery(query);

    if (query === "") {
      // If the search query is empty, show all items
      setTmpData(data);
    } else {
      // Filter items based on the search query
      const filteredItems = data.filter(
        (item) =>
          item.firstname.toLowerCase().includes(query) ||
          item.lastname.toLowerCase().includes(query) ||
          item.test_id.toString().toLowerCase().includes(query) // Assuming test_id is a number
      );

      setTmpData(filteredItems);
    }
  };

  return (
    <>
      <Header />
      <section class="pcoded-main-container">
        <div class="pcoded-wrapper">
          <div class="pcoded-content">
            <div class="pcoded-inner-content">
              {/* <!-- [ breadcrumb ] start --> */}
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Dashboard </h5>
                      </div>
                      <ul class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/billerdashboard">
                            <i class="feather icon-home"></i>
                          </Link>
                        </li>
                        <li class="breadcrumb-item">
                          <Link to="">Biller Profile</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- [ Hover-table ] start --> */}
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Search Order</h5>
                    <span class="d-block m-t-5">
                      search order by patient name
                    </span>
                    <div>
                      <div class="main-search">
                        <div class="input-group">
                          <input
                            type="text"
                            id="m-search"
                            class="form-control"
                            placeholder="Search . . ."
                            onChange={handleSearchChange}
                            value={searchQuery}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <Dialog
                    open={billerModal}
                    onClose={() => {
                      setBillerModal(false);
                    }}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle>
                      Order Details - {testId}
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          setBillerModal(false);
                        }}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <OrderInfo testId={testId} auth={auth} />
                    </DialogContent>
                  </Dialog>
                  <div class="card-block table-border-style">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Insurance</th>
                            {/* <th>Subscriber Id</th>
                              <th>Group</th> */}
                            <th>Order</th>
                            <th>Report</th>
                            <th>Satus</th>
                            <th>More</th>
                          </tr>
                        </thead>
                        <tbody>{orderLists}</tbody>
                      </table>
                    </div>
                    {/* pagination start */}
                    <Pagination
                      nPages={nPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- [ Hover-table ] end --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BillerDashboard;
