import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Header from "../Header"
import { API_URL } from "../apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom"


export default function AddClient() {
  const { user, auth } = useContext(AuthContext);
  const [clientForm, setclientForm] = useState({
    name: "",
    director: "",
    street_address: "",
    city: "",
    zip: "",
    state: "",
    contact: "",
  });

  const onChangeForm = (label, event) => {
    switch (label) {
      case "name":
        setclientForm({ ...clientForm, name: event.target.value });
        break;
      case "director":
        setclientForm({ ...clientForm, director: event.target.value });
        break;
      case "street_address":
        setclientForm({ ...clientForm, street_address: event.target.value });
        break;
      case "city":
        setclientForm({ ...clientForm, city: event.target.value });
        break;
      case "state":
        setclientForm({ ...clientForm, state: event.target.value });
        break;
      case "zip":
        setclientForm({ ...clientForm, zip: event.target.value });
        break;
      case "contact":
        setclientForm({ ...clientForm, contact: event.target.value });
        break;
      default:
        throw new Error(`Invalid option: ${label}`);
    }
  };

  const navigate = useNavigate();
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    // call api login
    await axios
      .post(`${API_URL}/client/register`, JSON.stringify(clientForm), {
        headers: {
          Authorization: auth,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        navigate("/client");
        toast.success(response.data.detail);
      })
      .catch((error) => {
        // add error notif

        // console.log(error);
        toast.error(error.response.data.detail[0].msg);
      });
  };
  return (
    <><Header />
    <div className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            {/* <!-- [ breadcrumb ] start --> */}
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admindashboard">
                          <i className="feather icon-home"></i>
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/client">Client</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/addclient">
                          Client Registration
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- [ breadcrumb ] end --> */}
            <div className="main-body">
              <div className="page-wrapper">
                {/* <!-- [ Main Content ] start --> */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Add New Client</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <form onSubmit={onSubmitHandler}>
                              <div className="form-group">
                                <label>Name</label>
                                <input
                                  id="name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Client Name"
                                  onChange={(event) => {
                                    onChangeForm("name", event);
                                  }}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label>Director</label>
                                <input
                                  id="director"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Director Name"
                                  onChange={(event) => {
                                    onChangeForm("director", event);
                                  }}
                                />
                              </div>
                              
                              <div className="form-group">
                                <label>Street Address</label>
                                <input
                                  id="street_street_address"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Street Address"
                                  onChange={(event) => {
                                    onChangeForm("street_address", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>city</label>
                                <input
                                  id="city"
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                  onChange={(event) => {
                                    onChangeForm("city", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>state</label>
                                <input
                                  id="state"
                                  type="text"
                                  className="form-control"
                                  placeholder="State"
                                  onChange={(event) => {
                                    onChangeForm("state", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>Zip</label>
                                <input
                                  id="zip"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Zip"
                                  onChange={(event) => {
                                    onChangeForm("zip", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                  id="phone"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your contact"
                                  onChange={(event) => {
                                    onChangeForm("contact", event);
                                  }}
                                />
                              </div>
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}