import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { API_URL } from "../apiConstants";
import EditField from "../EditField";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { fontWeight } from "@mui/system";

export default function ClientInfo(props) {
  const { auth, user } = useContext(AuthContext);
  const [client, setClient] = useState({});

  const [isEditing, setIsEditing] = useState(false); // Tracks whether editing mode is on
  // const [address, setAddress] = useState(system.address); // Current address (editable)
  const [tempValue, setTempValue] = useState(""); // Temporary address for the text box

  useEffect(() => {
    axios
      .get(`${API_URL}/client/details/${props.id}`, {
        headers: { Authorization: auth },
      })
      .then((response) => {
        setClient(response.data.result);
        // console.log(response.data.result);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  // ------------------------------------------------------------------

  const handleSave = (key, newName) => {
    // This function updates the state based on the key and newName
    setClient((prev) => ({ ...prev, [key]: newName })); // Use bracket notation for dynamic key
    // console.log(`Saved new ${key}:`, newName);
  };

  return (
    <div class="card-block table-border-style">
      <div class="table-responsive">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Client ID</TableCell>
                <TableCell>{client.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell>{client.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Director Name</TableCell>
                <TableCell>{client.director}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Contract ID</TableCell>
                <TableCell>{client.contract_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Contact</TableCell>
                <TableCell>
                  <EditField
                    type="textbox"
                    initialValue={client.contact ? client.contact : "-"}
                    onSave={(newValue) => handleSave("contact", newValue)} // Passing key "contact"
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Address</TableCell>
                <TableCell>
                  <EditField
                    type="textbox"
                    initialValue={client.street_address ? client.street_address : "-"}
                    onSave={(newValue) => handleSave("street_address", newValue)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>City</TableCell>
                <TableCell>
                  <EditField
                    type="textbox"
                    initialValue={client.city?client.city:"-"}
                    onSave={(newValue) => handleSave("city", newValue)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>State</TableCell>
                <TableCell>
                  <EditField
                    type="textbox"
                    initialValue={client.state?client.state:"-"}
                    onSave={(newValue) => handleSave("state", newValue)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Zip</TableCell>
                <TableCell>
                  <EditField
                    type="textbox"
                    initialValue={client.zip?client.zip:"-"}
                    onSave={(newValue) => handleSave("zip", newValue)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Created Date</TableCell>
                <TableCell>{client.modified_at}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell>
                  <EditField
                    type="selectbox"
                    initialValue={client.status}
                    options={["active", "inactive"]}
                    onSave={(newValue) => handleSave("status", newValue)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
