import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../Pagination";
import { API_URL } from "../apiConstants";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import Header from "../Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Download,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material"; // Import icons
// import UserList from "./UserList";
import UserUpdate from "./UserUpdate";

export default function TestList(props) {
  const { auth } = useContext(AuthContext);
  const [userModal, setUserModal] = useState(false);

  const [data, setData] = useState([]);
  const [userdetail, setUserdetail] = useState([]);

  const [tmpData, setTmpData] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const [refresh, setRefresh] = useState(false); // State to trigger data refresh

  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows per page
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // Handle sorting
  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  // Sort records based on the sort configuration
  const sortedRecords = [...(data || [])].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const order = sortConfig.direction === "asc" ? 1 : -1;

    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    // Handle sorting for string values
    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * order;
    }

    // Handle sorting for date and number values
    if (aValue instanceof Date || typeof aValue === "number") {
      return (aValue > bValue ? 1 : -1) * order;
    }

    return 0;
  });

  const filteredRecords = sortedRecords.filter((item) => {
    const name = item.name.toLowerCase();
    const performed = item.performed_with.toLowerCase();
    // const email = item.email.toLowerCase();
    return (
      name.includes(searchQuery.toLowerCase()) ||
      performed.includes(searchQuery.toLowerCase())
      // email.includes(searchQuery.toLowerCase())
    );
  });

  useEffect(() => {
    //  fetch data from get user api
    axios
      .get(`${API_URL}/tests/details/test?clinic=${props.id}`, {
        headers: { Authorization: auth },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.result);
          console.log(response.data.result);
          // setLoading(false);
          // setTmpData(data);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    if (page > Math.ceil(filteredRecords.length / rowsPerPage) - 1) {
      setPage(0);
    }
  }, [filteredRecords.length, rowsPerPage, page, refresh]);

  const paginatedRecords = filteredRecords.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const userLists = paginatedRecords.map((item, index) => (
    <TableRow
      key={index}
      className="table-row"
      // onClick={() => {
      //   setUserModal(true);
      //   setUserdetail(item);
      // }}
    >
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.abbr}</TableCell>
      <TableCell>{item.performed_with}</TableCell>
      {/* <TableCell>{item.email}</TableCell>
      <TableCell>{item.contact}</TableCell> */}
      {/* <TableCell>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            handleItemClick(item);
          }}
          className="feather icon-info"
        />
      </TableCell> */}
    </TableRow>
  ));

  const totalPages = Math.ceil(filteredRecords.length / rowsPerPage);
  // Generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(0, page - 2);
    const endPage = Math.min(totalPages - 1, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <TableContainer component={Paper}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        padding="16px"
        gap="0"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width={{ xs: "100%", sm: "100%" }}
        >
          <Box display="flex" alignItems="center" width="100%">
            <TextField
              placeholder="Search..."
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ marginBottom: "4px", flex: 1 }} // Allow the text field to take full width
            />
            <Link to="/patientregistration">
              <Button
                variant="contained"
                sx={{ marginLeft: "8px", backgroundColor: "#A389D4" }}
              >
                Add New
              </Button>
            </Link>
          </Box>
          <Typography variant="caption" color="textSecondary">
            Search records
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        padding="16px"
        gap="0"
      >
        <Dialog
          open={userModal}
          onClose={() => {
            setUserModal(false);
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Update User
            <IconButton
              aria-label="close"
              onClick={() => {
                setUserModal(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <UserUpdate
              auth={auth}
              user={userdetail}
              onSubmit={() => {
                setUserModal(false);
                setRefresh((prev) => !prev);
              }}
            />
          </DialogContent>
        </Dialog>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#A389D4",
                }}
              >
                Test Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#A389D4",
                }}
              >
                Abbr
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#A389D4",
                }}
              >
                Performed With
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>{userLists}</TableBody>
        </Table>
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <IconButton
          onClick={() => handleChangePage(null, page - 1)}
          disabled={page === 0 || filteredRecords.length === 0} // Disable if on the first page or no records
        >
          <ArrowBack />
        </IconButton>
        <div>
          {getPageNumbers().map((number) => (
            <button
              key={number}
              onClick={() => setPage(number)}
              style={{
                margin: "0 2px",
                background: page === number ? "#A389D4" : "transparent",
                color: page === number ? "white" : "black",
                border: "none",
                cursor: "pointer",
                padding: "5px 10px",
                borderRadius: "4px",
              }}
            >
              {number + 1}
            </button>
          ))}
        </div>
        <IconButton
          onClick={() => handleChangePage(null, page + 1)}
          disabled={page >= totalPages - 1 || filteredRecords.length === 0} // Disable if on the last page or no records
        >
          <ArrowForward />
        </IconButton>
        <span style={{ marginLeft: "10px" }}>
          Page {page + 1} of {totalPages}
        </span>
      </div>
    </TableContainer>
  );
}
