import { toast } from "react-toastify";
import { API_URL } from "./apiConstants";
import axios from "axios";

const generateFile = async (type, data, auth, setLoading) => {
  try {
    setLoading(true);
    // console.log(auth)
    const response = await axios.get(
      `${API_URL}/${type}/generate/${data}`,
      
      {
        responseType: "blob",
        headers: auth,
      }
    ); // Replace with the actual server URL of your file

    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      // Create a blob URL for the file content
      const fileUrl = URL.createObjectURL(blob);

      // Open a new tab to display the file
      const newTab = window.open(fileUrl, "_blank");
      
      newTab.focus();
    } else {
      console.error("Failed to generate the file from the server.");
    }
  } catch (error) {
    console.error("An error occurred:", error);
    toast.error("Unable to fetch file")
  } finally {
    setLoading(false);
  }
};

export default generateFile;
