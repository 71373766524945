import React, { useState, useEffect } from "react";
import { TextField, Button, Select, MenuItem } from "@mui/material";

const EditField = ({ type, initialValue, options = [], onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [tempValue, setTempValue] = useState(initialValue);

  // Ensure the tempValue is updated if initialValue changes
  useEffect(() => {
    setValue(initialValue);
    setTempValue(initialValue); // Update tempValue when initialValue changes
  }, [initialValue]);

  // Handle input change in edit mode
  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  // Save the new value
  const handleSave = () => {
    setValue(tempValue); // Update the displayed value
    onSave(tempValue); // Save the value (e.g., send it to a server or parent component)
    setIsEditing(false); // Exit edit mode
  };

  // Cancel and revert to the original value
  const handleCancel = () => {
    setTempValue(value); // Reset temp value
    setIsEditing(false); // Exit edit mode
  };

  return (
    <div>
      {isEditing ? (
        <div>
          {" "}
          {type === "textbox" ? (
            <TextField
              type="text"
              value={tempValue}
              onChange={handleInputChange}
              autoFocus
              size="small"
            />
          ) : type === "selectbox" ? (
            <Select
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              variant="outlined"
              size="small"
              // fullWidth
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          ) : null}
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      ) : (
        <label onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
          {value}
        </label>
      )}
    </div>
  );
};

export default EditField;
