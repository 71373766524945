import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";

export default function Login() {
  const [loginForm, setLoginform] = useState({
    username: "",
    password: "",
  });

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const onChangeForm = (label, event) => {
    switch (label) {
      case "username":
        setLoginform({ ...loginForm, username: event.target.value });
        break;
      case "password":
        setLoginform({ ...loginForm, password: event.target.value });
        break;
      default:
        throw new Error(`Invalid option: ${label}`);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const user = await login(loginForm);
      toast.success("success");
      if (user.role_name === 'admin') {
        navigate('/admindashboard');
      } else if (user.role_name === 'biller') {
        navigate('/billerdashboard')
      } else {
        navigate('/'); 
      }
    } catch (error) {
      // console.log(error)
      toast.error('Login failed');
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>
        <div className="card">
          <div id="root" className="card-body text-center">
            <div className="mb-4">
              <i className="feather icon-unlock auth-icon"></i>
            </div>
            <h3 className="mb-4">Login</h3>
            <form onSubmit={onSubmitHandler}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  placeholder="Username"
                  className="form-control"
                  onChange={(event) => {
                    onChangeForm("username", event);
                  }}
                  required
                />
              </div>
              <div className="input-group mb-4">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={(event) => {
                    onChangeForm("password", event);
                  }}
                  required
                />
              </div>
              <div className="form-group text-left">
                <div className="checkbox checkbox-fill d-inline">
                  <input
                    type="checkbox"
                    name="checkbox-fill-1"
                    id="checkbox-fill-a1"
                    defaultChecked={false}
                  />
                  <label htmlFor="checkbox-fill-a1" className="cr">
                    {" "}
                    Save Details
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary shadow-2 mb-4"
                onClick={onSubmitHandler}
              >
                Login
              </button>
            </form>
            <p className="mb-2 text-muted">
              Forgot password? <Link to="reset-password.html">Reset</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
