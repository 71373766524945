import React, { useEffect, useState } from "react";
import {
  Root,
  Section,
  FormControlStyled,
  ButtonStyled,
} from "../StyledComponents";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
// import { error } from "pdf-lib";
import { API_URL } from "../apiConstants";
// import { Link } from "react-router-dom";

export default function UserUpdate(props) {
  // const { auth } = useContext(AuthContext);
  const [newPass, setNewPass] = useState("");

  const handleResetPassword = async () => {
    axios
      .post(
        `${API_URL}/auth/forgot-password`,
        {
          'email': props.user.email,
          'new_password': newPass,
        },
        { headers: { Authorization: props.auth } }
      )
      .then((response) => {
        toast.success("Password Updated")
        props.onSubmit();
      }).catch((error)=>{
        toast.error("error")
        // console.log(error)
      });
  };
  const handlePassChange = (event) => {
    setNewPass(event.target.value);
  };
  return (
    <Root>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box flex={1} mr={1}>
          <Typography variant="h6" gutterBottom>
            User Information
          </Typography>
          <Section>
            <Typography variant="body1">
              First Name: {props.user.firstname}
            </Typography>
            <Typography variant="body1">Last Name: {props.user.lastname}</Typography>
            <Typography variant="body1">Email: {props.user.email}</Typography>
            <Typography variant="body1">Username: {props.user.username}</Typography>
            <Typography variant="body1">
              Phone Number: {props.user.contact}
            </Typography>
          </Section>
        </Box>
        <Box flex={1} ml={1}>
          <Typography variant="h6" gutterBottom>
            Reset Password
          </Typography>
          <Section>
            <Typography variant="body1">
               <TextField
              type="password"
        label="New Password"
        variant="outlined"
        fullWidth
        // value={newPass}
        onChange={handlePassChange}
        className="section"
        sx={{ marginBottom: "20px" }}
      /><ButtonStyled
      variant="contained"
      color="primary"
      onClick={handleResetPassword}
    >
      Submit
    </ButtonStyled>
              </Typography>
          </Section>
        </Box>
      </Box>
      
    </Root>
  );
}
