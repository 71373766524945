import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Header from "../Header"
import { API_URL } from "../apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom"

export default function AddUser() {
  const { user, auth } = useContext(AuthContext);
  const [userForm, setuserForm] = useState({
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    role: "",
    password: "",
    contact: "",
    clinic: 0,
    site: 0
  });


  const onChangeForm = (label, event) => {
    switch (label) {
      case "username":
        setuserForm({ ...userForm, username: event.target.value });
        break;
      case "password":
        setuserForm({ ...userForm, password: event.target.value });
        break;
      case "email":
        setuserForm({ ...userForm, email: event.target.value });
        break;
      case "firstname":
        setuserForm({ ...userForm, firstname: event.target.value });
        break;
      case "lastname":
        setuserForm({ ...userForm, lastname: event.target.value });
        break;
      case "role":
        setuserForm({ ...userForm, role: event.target.value });
        break;
      case "site":
        setuserForm({ ...userForm, site: parseInt(event.target.value, 10) });
        break;
      case "clinic":
        setuserForm({ ...userForm, clinic: parseInt(event.target.value, 10) });
        break;
      case "contact":
        setuserForm({ ...userForm, contact: event.target.value });
        break;
      default:
        throw new Error(`Invalid option: ${label}`);
    }
  };

  const navigate = useNavigate();
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    // call api login
    await axios
      .post(`${API_URL}/auth/register`, JSON.stringify(userForm), {
        headers: {
          Authorization: auth,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        navigate("/user");
        toast.success(response.data.detail);
      })
      .catch((error) => {
        // add error notif

        // console.log(error);
        toast.error(error.response.data.detail[0].msg);
      });
  };
  return (
    <><Header />
    <div className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            {/* <!-- [ breadcrumb ] start --> */}
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admindashboard">
                          <i className="feather icon-home"></i>
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/user">User</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/adduser">
                          User Registration
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- [ breadcrumb ] end --> */}
            <div className="main-body">
              <div className="page-wrapper">
                {/* <!-- [ Main Content ] start --> */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Add New User</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <form onSubmit={onSubmitHandler}>
                            <div className="form-group">
                                <label>Role</label>
                                <input
                                  id="role"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Role"
                                  onChange={(event) => {
                                    onChangeForm("role", event);
                                  }}
                                  required
                                />
                              
                              </div>
                              <div className="form-group">
                                <label>First Name</label>
                                <input
                                  id="firstname"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter First Name"
                                  onChange={(event) => {
                                    onChangeForm("firstname", event);
                                  }}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  id="lastname"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  onChange={(event) => {
                                    onChangeForm("lastname", event);
                                  }}
                                />
                              </div>
                              
                              <div className="form-group">
                                <label>username</label>
                                <input
                                  id="username"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Username"
                                  onChange={(event) => {
                                    onChangeForm("username", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>Password</label>
                                <input
                                  id="password"
                                  type="password"
                                  className="form-control"
                                  placeholder="Password"
                                  onChange={(event) => {
                                    onChangeForm("password", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>email</label>
                                <input
                                  id="email"
                                  type="text"
                                  className="form-control"
                                  placeholder="email"
                                  onChange={(event) => {
                                    onChangeForm("email", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>Contact</label>
                                <input
                                  id="contact"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Contact"
                                  onChange={(event) => {
                                    onChangeForm("contact", event);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label>Clinic</label>
                                <input
                                  id="clinic"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Clinic"
                                  onChange={(event) => {
                                    onChangeForm("clinic", event);
                                  }}
                                />
                              </div>
                              
                              <div className="form-group">
                                <label>Site</label>
                                <input
                                  id="site"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your site"
                                  onChange={(event) => {
                                    onChangeForm("site", event);
                                  }}
                                />
                              </div>
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}