import React from 'react'
import { Link } from "react-router-dom";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    

    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
            scrollToTop();
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
        scrollToTop();
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 200,
          behavior: 'instant', // Optional: Use smooth scrolling
        });
      };
    return (
        <nav>
            <ul className='pagination justify-content-end'>
                <li className={`page-item ${currentPage === 0 ? 'disabled' : ''} `}>
                    <Link className="page-link" 
                        onClick={prevPage} 
                        href='#'>
                        
                        Previous
                    </Link>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >

                        <Link onClick={() => {setCurrentPage(pgNumber); scrollToTop();}}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </Link>
                    </li>
                ))}
                <li className="page-item">
                    <Link className="page-link" 
                        onClick={nextPage}
                        href='#'>
                        
                        Next
                    </Link>
                </li>
            </ul>
        </nav>
        
    )
}

export default Pagination