import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#f9f9f9',
  textAlign: 'center',
  padding: '10px 0',
  borderTop: '1px solid #e0e0e0',
});

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} All rights reserved.
      </Typography>
    </FooterContainer>
  );
};

export default Footer;
