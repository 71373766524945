import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Header from "../Header"
import { Link } from "react-router-dom"

export default function AddProvider() {
  const { user, auth } = useContext(AuthContext);
  
  return (
    <>
      <Header />
      <h2>Add Provider</h2>
    </>
  );
}