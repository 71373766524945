import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../apiConstants";
import moment from "moment";

import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import {
  Root,
  Section,
  FormControlStyled,
  ButtonStyled,
} from "../StyledComponents";

export default function OrderInfo(props) {
  const { user, auth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [diagnose, setDiagnose] = useState({});

  const [status, setStatus] = useState("ready");
  const [icd, setIcd] = useState("");
  const [cpt, setCpt] = useState("");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    axios
                .put(
                  `${API_URL}/orders/update/state/${props.testId}`,
                  { status: event.target.value },
                  {
                    headers: { Authorization: auth },
                  }
                )
                .then((response) => {
                  // console.log(response.data.result);
                  // fetchOrdersDetails()
                  
                })
                .catch((error) => {
                  // console.log(error);
                });
  };

  const handleIcdChange = (event) => {
    setIcd(event.target.value);
  };

  const handleCptChange = (event) => {
    setCpt(event.target.value);
  };

  const handleOrderSubmit = async () => {
    axios
      .post(
        `${API_URL}/diagnose/add`,
        {
          'icd': icd,
          'cpt': cpt,
          'test_id': props.testId,
        },
        { headers: { Authorization: auth } }
      )
      .then((response) => {
        toast.success("Diagnose code Added")
      }).catch((error)=>{
        toast.error("Error adding diagnose code")
        // console.log(error)
      });
  };
  const fetchOrdersDetails = ()=> {
    axios
      .get(`${API_URL}/orders/details/test/${props.testId}`, {
        headers: { Authorization: auth },
      })
      .then((response) => {
        setData(response.data.result);
        setStatus(response.data.result.status)
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .get(`${API_URL}/diagnose/details/${props.testId}`, {
        headers: { Authorization: auth },
      })
      .then((response) => {
        setDiagnose(response.data.result);
        setCpt(response.data.result.cpt);
      setIcd(response.data.result.icd);
      })
      .catch((error) => {
        // console.log(error)
      });
  }
  useEffect(() => {
    //  fetch data from get user api
    fetchOrdersDetails()
  }, []);

  return (
    <Root>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box flex={1} mr={1}>
          <Typography variant="h6" gutterBottom>
            Patient Information
          </Typography>
          <Section>
            <Typography variant="body1">
              First Name: {data.firstname}
            </Typography>
            <Typography variant="body1">Last Name: {data.lastname}</Typography>
            <Typography variant="body1">
              DOB: {moment(data.birth).format("MM/DD/YYYY")}
            </Typography>
            <Typography variant="body1">Address: {data.address}</Typography>
            <Typography variant="body1">City: {data.city}</Typography>
            <Typography variant="body1">State: {data.state}</Typography>
            <Typography variant="body1">Zip: {data.zip}</Typography>
            <Typography variant="body1">Gender: {data.sex}</Typography>
            <Typography variant="body1">Email: {data.email}</Typography>
            <Typography variant="body1">
              Phone Number: {data.phone_number}
            </Typography>
          </Section>
        </Box>
        <Box flex={1} ml={1}>
          <Typography variant="h6" gutterBottom>
            Insurance Information
          </Typography>
          <Section>
            <Typography variant="body1">
              Name: {data.biller === "Other" ? data.billername : data.biller}
            </Typography>
            <Typography variant="body1">
              Benificiary: {data.billingfor}
            </Typography>
            <Typography variant="body1">
              Subscriber ID: {data.benificiary}
            </Typography>
            <Typography variant="body1">Group: {data.group}</Typography>
          </Section>
        </Box>
      </Box>

      <FormControlStyled>
        <InputLabel>Status</InputLabel>
        <Select value={status} onChange={handleStatusChange}>
          <MenuItem value="ready">Ready</MenuItem>
          <MenuItem value="inprogress">In Progress</MenuItem>
          <MenuItem value="sent">Sent</MenuItem>
          <MenuItem value="error">Error</MenuItem>
          <MenuItem value="accept">Accept</MenuItem>
        </Select>
      </FormControlStyled><br />
<label>- <i>use comma for seperation</i></label>
      <TextField
        label="ICD"
        variant="outlined"
        fullWidth
        value={icd}
        onChange={handleIcdChange}
        className="section"
        sx={{ marginBottom: "20px" }}
      />

      <TextField
        label="CPT"
        variant="outlined"
        fullWidth
        value={cpt}
        onChange={handleCptChange}
      />

      <ButtonStyled
        variant="contained"
        color="primary"
        onClick={handleOrderSubmit}
      >
        Submit
      </ButtonStyled>
    </Root>
  );
}
