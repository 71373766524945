import React, { useEffect, useState, useRef, useContext } from "react";
import { toast } from "react-toastify";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import axios from "axios";
import { API_URL } from "./apiConstants";
import Footer from "./Footer";


export default function Header(props) {
  const { user, logout, auth } = useContext(AuthContext);
  const [clinic, setClinic] = useState(null)

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const handleMobileMenuClick = () => {
    
    if (window.innerWidth < 992) {
      setIsMobileMenuOpen((prevIsMobileMenuOpen) => !prevIsMobileMenuOpen);
    }
  };
  const mobileMenuClass = isMobileMenuOpen ? "mobile-menu on" : "mobile-menu";

  useEffect(() => {
    
    axios
    .get(`${API_URL}/client/details/${user.clinic}`, {
      headers: { Authorization: auth },
    })
    .then((response) => {
      setClinic(response.data.result.name);
      // console.log(response.data.result.name);
    })
    .catch((error) => {
      // console.log(error);
    });
    const handleDocumentClick = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        // Clicked outside the mobile menu, so close it
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  const onClickHandler = (event) => {
    event.preventDefault();

    // remove token form local storage
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_token_type");

    // notify
    toast("See You !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // reload page
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <>
      <NavBar mob={isMobileMenuOpen} clinic={clinic}/>
      <header className="navbar pcoded-header navbar-expand-lg navbar-light">
        <div className="m-header">
          <Link
            className={mobileMenuClass}
            onClick={handleMobileMenuClick} ref={mobileMenuRef}
            id="mobile-collapse1"
          >
            <span></span>
          </Link>
          <Link to="/" className="b-brand">
            <div className="b-bg">
              <i className="feather icon-activity"></i>
            </div>
            <span className="b-title">{clinic && clinic.split(" ")[0]}</span>
          </Link>
        </div>
        <Link className="mobile-menu" id="mobile-header">
          <i className="feather icon-more-horizontal"></i>
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li>
              <Link className="full-screen" onClick={toggleFullScreen}>
                {isFullScreen ? (
                  <i className="feather icon-minimize"></i>
                ) : (
                  <i className="feather icon-maximize"></i>
                )}
              </Link>
            </li>
            <li className="nav-item">
              <div className="main-search">
                <div className="input-group">
                  <input
                    type="text"
                    id="m-search"
                    className="form-control"
                    placeholder="Search . . ."
                  />
                  <Link className="input-group-append search-close">
                    <i className="feather icon-x input-group-text"></i>
                  </Link>
                  <span className="input-group-append search-btn btn btn-primary">
                    <i className="feather icon-search input-group-text"></i>
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li>
              <div className="dropdown">
                <Link
                  className="dropdown-toggle"
                  
                  data-toggle="dropdown"
                >
                  <i className="icon feather icon-bell"></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-right notification">
                  <div className="noti-head">
                    <h6 className="d-inline-block m-b-0">Notifications</h6>
                    <div className="float-right">
                      <Link  className="m-r-10">
                        mark as read
                      </Link>
                      <Link >clear all</Link>
                    </div>
                  </div>
                  <ul className="noti-body">
                    <li className="n-title">
                      <p className="m-b-0">NEW</p>
                    </li>
                    <li className="notification">
                      <div className="media">
                        <img
                          className="img-radius"
                          src="assets/images/user/avatar-1.jpg"
                          alt="avatar-1"
                        />
                        <div className="media-body">
                          <p>
                            <strong>@{user.firstname}</strong>
                            <span className="n-time text-muted">
                              <i className="icon feather icon-clock m-r-10"></i>
                              30 min
                            </span>
                          </p>
                          <p>New ticket Added</p>
                        </div>
                      </div>
                    </li>
                    <li className="n-title">
                      <p className="m-b-0">EARLIER</p>
                    </li>
                    <li className="notification">
                      <div className="media">
                        <img
                          className="img-radius"
                          src="assets/images/user/avatar-2.jpg"
                          alt="avatar-2"
                        />
                        <div className="media-body">
                          <p>
                            <strong>Joseph William</strong>
                            <span className="n-time text-muted">
                              <i className="icon feather icon-clock m-r-10"></i>
                              30 min
                            </span>
                          </p>
                          <p>Prchace New Theme and make payment</p>
                        </div>
                      </div>
                    </li>
                    <li className="notification">
                      <div className="media">
                        <img
                          className="img-radius"
                          src="assets/images/user/avatar-3.jpg"
                          alt="avatar-3"
                        />
                        <div className="media-body">
                          <p>
                            <strong>Sara Soudein</strong>
                            <span className="n-time text-muted">
                              <i className="icon feather icon-clock m-r-10"></i>
                              30 min
                            </span>
                          </p>
                          <p>currently login</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="noti-footer">
                    <Link >show all</Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown drp-user">
                <Link
                  
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="icon feather icon-settings"></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-right profile-notification">
                  <div className="pro-head">
                    <img
                      src="assets/images/user/avatar-1.jpg"
                      className="img-radius"
                      alt="User-Profile"
                    />
                    <span>
                      {user.firstname + " " + user.lastname}
                    </span>
                    <Link
                      href="/"
                      className="dud-logout"
                      title="Logout"
                      onClick={logout}
                    >
                      <i className="feather icon-log-out"></i>
                    </Link>
                  </div>
                  <ul className="pro-body">
                    <li>
                      <Link  className="dropdown-item">
                        <i className="feather icon-settings"></i> Settings
                      </Link>
                    </li>
                    <li>
                      <Link  className="dropdown-item">
                        <i className="feather icon-user"></i> Profile
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item">
                        <i className="feather icon-mail"></i> My Messages
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item">
                        <i className="feather icon-lock"></i> Lock Screen
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </header>
      <Footer />
    </>
  );
}
