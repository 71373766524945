import React from 'react';

const Unauthorized = () => {
  return (
    <div>
      <h2>Not Authorized</h2>
      <p>You do not have access to this page</p>
    </div>
  );
};
export default Unauthorized;