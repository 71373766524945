import { AuthContext } from "../../context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import { API_URL } from "../apiConstants";
import { Link } from "react-router-dom";
import generateFile from "../FileGenerator";
import moment from "moment";
import { toast } from "react-toastify";

export default function Home() {
  const { user, auth } = useContext(AuthContext);
  // const [clinic, setClinic] = useState(null)
  const [orders, setOrders] = useState(null);
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    try {
      //  fetch data from get Orders api
      axios
        .get(`${API_URL}/orders/orderlist/${user.clinic}`, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          setOrders(response.data.result.slice(0, 5));
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {
      toast.error("unable to connect");
    }
  }, []);

  const recentOrders = (orders || []).map((order) => (
    <tr className="unread">
      <td>
        <img
          className="rounded-circle"
          src="assets/images/user/avatar-1.jpg"
          alt="activity-user"
          style={{ width: "40px" }}
        />
      </td>
      <td>
        <h6 className="mb-1">{order.firstname + " " + order.lastname}</h6>
        <p className="m-0">Tests: {order.test_id}</p>
      </td>
      <td>
        <h6 className="mb-1">{moment(order.birth).format("MM/DD/YYYY")}</h6>
      </td>
      <td>
        <h6 className="text-muted">
          <i className="fas fa-circle text-c-green f-10 m-r-15"></i>
          {moment(order.modified_at).format("DD MMM HH:mm").toUpperCase()}
        </h6>
      </td>
      <td>
        <Link
          to="#!"
          className="label theme-bg text-white f-12"
          onClick={() => {
            generateFile("orders", order.test_id, { Authorization: auth }, setIsLoading);
          }}
        >
          &nbsp;&nbsp;Requisition&nbsp;&nbsp;
        </Link>
      </td>
    </tr>
  ));

  
  return (
    <>
      <Header />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-xl-8 col-md-6">
                      <div className="card Recent-Users">
                        <div className="card-header">
                          <h5>Recent Orders</h5>
                        </div>
                        <div className="card-block px-0 py-3">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <tbody>{recentOrders}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}