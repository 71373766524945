import React, { useState, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export default function NavBar(props) {
  const { user } = useContext(AuthContext);

  // const [menuState, setMenuState] = useState({
  //   expanded: false,
  // });

  const isActive = (paths) => {
    if (!pathname || !Array.isArray(paths)) return false;
    return paths.includes(pathname);
  };

  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen((prevIsMobileMenuOpen) => !prevIsMobileMenuOpen);
  };

  // const handleMenuTrigger = () => {
  //   setMenuState({
  //     expanded: !menuState.expanded,
  //   });
  // };
  const displayMobileMenuClass = props.mob
    ? "pcoded-navbar mob-open"
    : "pcoded-navbar";
  const MenuClass = isMenuOpen ? "mobile-menu on" : "mobile-menu";
  const displayMenuClass = isMenuOpen
    ? "pcoded-navbar navbar-collapsed"
    : "pcoded-navbar";

  return (
    <nav className={isMenuOpen ? displayMenuClass : displayMobileMenuClass}>
      <div className="navbar-wrapper">
        <div className="navbar-brand header-logo">
          <NavLink to="/" className="b-brand">
            <div className="b-bg">
              <i className="feather icon-activity"></i>
            </div>
            <span className="b-title">
              {props.clinic && props.clinic.split(" ")[0]}
            </span>
          </NavLink>
          <NavLink
            className={MenuClass}
            id="mobile-collapse"
            onClick={handleMenuClick}
          >
            <span></span>
          </NavLink>
        </div>
        <div className="navbar-content scroll-div">
          <ul className="nav pcoded-inner-navbar">
            {user ? (
              <>
                {user.role_name === "admin" && (
                  <>
                  <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/admindashboard"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/admindashboard" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-home"></i>
                        </span>
                        <span className="pcoded-mtext">Home</span>
                      </NavLink>
                    </li>
                  {/* <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/user", "/adduser"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/user" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-user"></i>
                        </span>
                        <span className="pcoded-mtext">Users</span>
                      </NavLink>
                    </li> */}
                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/test"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/test" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-aperture"></i>
                        </span>
                        <span className="pcoded-mtext">Tests</span>
                      </NavLink>
                    </li>

                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/client","/clientlist", "/sites","/addclient", "/clientinfo"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/clientlist" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-award"></i>
                        </span>
                        <span className="pcoded-mtext">Clients</span>
                      </NavLink>
                    </li>
                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/provider"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/provider" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-user-plus"></i>
                        </span>
                        <span className="pcoded-mtext">Providers</span>
                      </NavLink>
                    </li>
                  </>
                )}
                {user.role_name === "biller" && (
                  <>
                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/billerdashboard"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/billerdashboard" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-home"></i>
                        </span>
                        <span className="pcoded-mtext">Home</span>
                      </NavLink>
                    </li>
                    
                  </>
                )}
                {user.role_name === "user" && (
                  <>
                    
                    <li className="nav-item pcoded-menu-caption">
                      <label>Navigation</label>
                    </li>
                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-home"></i>
                        </span>
                        <span className="pcoded-mtext">Dashboard</span>
                      </NavLink>
                    </li>

                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive([
                          "/patient",
                          "/patientregistration",
                          "/patientinfo",
                          "/patientdetail",
                        ])
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <NavLink to="/patient" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-users"></i>
                        </span>
                        <span className="pcoded-mtext">Patients</span>
                      </NavLink>
                    </li>
                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/orderlist"])
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <NavLink to="/orderlist" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-droplet"></i>
                        </span>
                        <span className="pcoded-mtext">Order / Result</span>
                      </NavLink>
                    </li>
                    <li
                      data-username="dashboard Default labs2run LIS"
                      className={
                        isActive(["/results"]) ? "nav-item active" : "nav-item"
                      }
                    >
                      <NavLink to="/results" className="nav-NavLink ">
                        <span className="pcoded-micon">
                          <i className="feather icon-download"></i>
                        </span>
                        <span className="pcoded-mtext">Pull Results</span>
                      </NavLink>
                    </li>
                  </>
                )}
              </>
            ) : (
              <li>
                <NavLink to="/login">Login</NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
