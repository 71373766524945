// import logo from './logo.svg';
import React, {useContext, lazy, Suspense } from "react";
// import { AuthContext } from "./context/AuthContext";
import "./App.css";
import Login from "./components/Login";
import Home from "./components/user/Home";
import Unauthorized from './components/Unauthorized';
import { AuthProvider } from "./context/AuthContext";
import AdminDashboard from './components/admin/AdminDashboard';
import BillerDashboard from './components/biller/BillerDashboard';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from './context/PrivateRoute';
import ProviderList from "./components/admin/ProviderList";
import TestList from "./components/admin/TestList";
import ClientList from "./components/admin/ClientList";
import Client from "./components/admin/Client";
import ClientInfo from "./components/admin/ClientInfo";
import UserList from "./components/admin/UserList";
import AddProvider from "./components/admin/AddProvider";
import AddClient from "./components/admin/AddClient";
import AddUser from "./components/admin/AddUser";
const PatientList = lazy(() => import("./components/user/PatientList"));
const PatientRegistration = lazy(() =>
  import("./components/user/PatientRegistration")
);
const PatientInfo = lazy(() => import("./components/user/PatientInfo"));
const Provider = lazy(() => import("./components/admin/Provider"));
const Staff = lazy(() => import("./components/admin/Staff"));
const Tests = lazy(() => import("./components/admin/Tests"));
const OrderList = lazy(() => import("./components/user/OrderList"));
const Results = lazy(() => import("./components/user/Results"));
const ResultList = lazy(() => import("./components/user/ResultList"));
const Patient = lazy(() => import("./components/user/patient/Patient"));
const Header = lazy(() => import("./components/Header"));



function App() {
  // const { user, auth } = useContext(AuthContext);

  
  const styles = {
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    loadingMessage: {
      fontSize: "50px",
      fontWeight: "bold",
    },
  };

  return (
    <AuthProvider>
      <Router>

      <Suspense
          fallback={
            <div style={styles.loadingContainer}>
              <div style={styles.loadingMessage}>Loading...</div>
            </div>
          }
        >
          {/* <Header user={user}/> */}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            
            <Route path="/" element={<PrivateRoute allowedRoles={['user']}><Home /></PrivateRoute>} />
            <Route path="/patient" element={<PrivateRoute allowedRoles={['user']}><PatientList /></PrivateRoute>} />
            <Route path="/patientdetail" element={<PrivateRoute allowedRoles={['user']}><Patient /></PrivateRoute>} />
            <Route path="/patientregistration" element={<PrivateRoute allowedRoles={['user']}><PatientRegistration /></PrivateRoute>} />
            <Route path="/patientinfo" element={<PrivateRoute allowedRoles={['user']}><PatientInfo /></PrivateRoute>} />
            <Route path="/resultinfo" element={<PrivateRoute allowedRoles={['user']}><ResultList /></PrivateRoute>} />
            <Route path="/results" element={<PrivateRoute allowedRoles={['user']}><Results /></PrivateRoute>} />
            <Route path="/orderlist" element={<PrivateRoute allowedRoles={['user']}><OrderList /></PrivateRoute>} />
            
            <Route path="/user" element={<PrivateRoute allowedRoles={['admin']}><UserList /></PrivateRoute>} />
            <Route path="/adduser" element={<PrivateRoute allowedRoles={['admin']}><AddUser /></PrivateRoute>} />
            <Route path="/test" element={<PrivateRoute allowedRoles={['admin']}><TestList /></PrivateRoute>} />
            <Route path="/provider" element={<PrivateRoute allowedRoles={['admin']}><ProviderList /></PrivateRoute>} />
            <Route path="/addprovider" element={<PrivateRoute allowedRoles={['admin']}><AddProvider /></PrivateRoute>} />
            <Route path="/clientlist" element={<PrivateRoute allowedRoles={['admin']}><ClientList /></PrivateRoute>} />
            <Route path="/client" element={<PrivateRoute allowedRoles={['admin']}><Client /></PrivateRoute>} />
            <Route path="/addclient" element={<PrivateRoute allowedRoles={['admin']}><AddClient /></PrivateRoute>} />
            <Route path="/admindashboard" element={<PrivateRoute allowedRoles={['admin']}><AdminDashboard /></PrivateRoute> } />
            <Route path="/clientinfo" element={<PrivateRoute allowedRoles={['admin']}><ClientInfo /></PrivateRoute> } />
            
            <Route path="/billerdashboard" element={<PrivateRoute allowedRoles={['biller']}><BillerDashboard /></PrivateRoute> } />
          </Routes>
          </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
