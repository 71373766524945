import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../Pagination";
import { API_URL } from "../apiConstants";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Download,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material"; // Import icons

export default function ClientList() {
  const { user, auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tmpData, setTmpData] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows per page
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
    // Handle sorting
    const handleSort = (columnKey) => {
      let direction = "asc";
      if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key: columnKey, direction });
    };

  // Sort records based on the sort configuration
  const sortedRecords = [...(data || [])].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const order = sortConfig.direction === "asc" ? 1 : -1;

    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    // Handle sorting for string values
    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * order;
    }

    // Handle sorting for date and number values
    if (aValue instanceof Date || typeof aValue === "number") {
      return (aValue > bValue ? 1 : -1) * order;
    }

    return 0;
  });

  const filteredRecords = sortedRecords.filter((item) => {
    const name = item.name.toLowerCase();
    const director = item.director.toLowerCase();
    const phoneNumber = item.phone_number;
    return (
      name.includes(searchQuery.toLowerCase()) ||
      director.includes(searchQuery.toLowerCase()) ||
      phoneNumber.includes(searchQuery.toLowerCase())
    );
  });

  useEffect(() => {
    //  fetch data from get user api
    axios
      .get(`${API_URL}/client/details`, {
        headers: { Authorization: auth },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.result);
          console.log(response.data.result)
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    if (page > Math.ceil(filteredRecords.length / rowsPerPage) - 1) {
      setPage(0);
    }
  }, [filteredRecords.length, rowsPerPage, page]);

  const paginatedRecords = filteredRecords.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const clientLists = paginatedRecords.map((item, index) => (
    <TableRow
      key={index}
      className="table-row"
      onClick={() => {
        navigate("/client", { state: item });
      }}
    >
      <TableCell>{item.id}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.director}</TableCell>
      <TableCell>{item.contact}</TableCell>
      <TableCell>{item.contract_id}</TableCell>
      {/* <TableCell>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            handleItemClick(item);
          }}
          className="feather icon-info"
        />
      </TableCell> */}
    </TableRow>
  ));

  const totalPages = Math.ceil(filteredRecords.length / rowsPerPage);
  // Generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(0, page - 2);
    const endPage = Math.min(totalPages - 1, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <>
      <Header />
      <section class="pcoded-main-container">
        <div class="pcoded-wrapper">
          <div class="pcoded-content">
            <div class="pcoded-inner-content">
              {/* <!-- [ breadcrumb ] start --> */}
              <div class="page-header">
                <div class="page-block">
                  <div class="row align-items-center">
                    <div class="col-md-12">
                      <div class="page-header-title">
                        <h5 class="m-b-10">Clients </h5>
                      </div>
                      <ul class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/admindashboard">
                            <i class="feather icon-home"></i>
                          </Link>
                        </li>
                        <li class="breadcrumb-item">
                          <Link to="/client">Client List</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- [ Hover-table ] start --> */}
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <h5>Search Client</h5>
                    <TableContainer component={Paper}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        justifyContent="space-between"
                        padding="16px"
                        gap="0"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          width={{ xs: "100%", sm: "100%" }}
                        >
                          <Box display="flex" alignItems="center" width="100%">
                            <TextField
                              placeholder="Search..."
                              variant="outlined"
                              size="small"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              sx={{ marginBottom: "4px", flex: 1 }} // Allow the text field to take full width
                            />
                            <Link to="/patientregistration">
                              <Button
                                variant="contained"
                                sx={{
                                  marginLeft: "8px",
                                  backgroundColor: "#A389D4",
                                }}
                              >
                                Add New
                              </Button>
                            </Link>
                          </Box>
                          <Typography variant="caption" color="textSecondary">
                            Search records
                          </Typography>
                        </Box>

                        
                      </Box>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              onClick={() => handleSort("id")}
                              sx={{
                                fontWeight: "bold",
                                color: "#A389D4",
                                cursor: "pointer",
                              }}
                            >
                              ID
                              {sortConfig.key === "id" &&
                                (sortConfig.direction === "asc" ? (
                                  <ArrowDropUp />
                                ) : (
                                  <ArrowDropDown />
                                ))}
                            </TableCell>
                            <TableCell
                              onClick={() => handleSort("name")}
                              sx={{
                                fontWeight: "bold",
                                color: "#A389D4",
                                cursor: "pointer",
                              }}
                            >
                              Name
                              {sortConfig.key === "name" &&
                                (sortConfig.direction === "asc" ? (
                                  <ArrowDropUp />
                                ) : (
                                  <ArrowDropDown />
                                ))}
                            </TableCell>
                            <TableCell
                              onClick={() => handleSort("director")}
                              sx={{
                                fontWeight: "bold",
                                color: "#A389D4",
                                cursor: "pointer",
                              }}
                            >
                              Director Name
                              {sortConfig.key === "director" &&
                                (sortConfig.direction === "asc" ? (
                                  <ArrowDropUp />
                                ) : (
                                  <ArrowDropDown />
                                ))}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                color: "#A389D4",
                                // cursor: "pointer",
                              }}
                            >
                              Contact
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                color: "#A389D4",
                                // cursor: "pointer",
                              }}
                            >
                              Client Info
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                color: "#A389D4",
                                // cursor: "pointer",
                              }}
                            >
                              &nbsp;
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{clientLists}</TableBody>
                      </Table>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "16px",
                        }}
                      >
                        <IconButton
                          onClick={() => handleChangePage(null, page - 1)}
                          disabled={page === 0 || filteredRecords.length === 0} // Disable if on the first page or no records
                        >
                          <ArrowBack />
                        </IconButton>
                        <div>
                          {getPageNumbers().map((number) => (
                            <button
                              key={number}
                              onClick={() => setPage(number)}
                              style={{
                                margin: "0 2px",
                                background:
                                  page === number ? "#A389D4" : "transparent",
                                color: page === number ? "white" : "black",
                                border: "none",
                                cursor: "pointer",
                                padding: "5px 10px",
                                borderRadius: "4px",
                              }}
                            >
                              {number + 1}
                            </button>
                          ))}
                        </div>
                        <IconButton
                          onClick={() => handleChangePage(null, page + 1)}
                          disabled={
                            page >= totalPages - 1 ||
                            filteredRecords.length === 0
                          } // Disable if on the last page or no records
                        >
                          <ArrowForward />
                        </IconButton>
                        <span style={{ marginLeft: "10px" }}>
                          Page {page + 1} of {totalPages}
                        </span>
                      </div>
                    </TableContainer>
                  </div>
                </div>
              </div>
              {/* <!-- [ Hover-table ] end --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
