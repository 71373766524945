import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "../components/apiConstants";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [folder, setFolder] = useState(null);
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = 'Bearer ' + localStorage.getItem("token");
    setAuth(token)
    // console.log(token);
    if (token && isTokenValid(token)) {
      axios
        .get(`${API_URL}/users`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setUser(response.data.result);
          setLoading(false);
        })
        .catch(() => {
          localStorage.removeItem("token");
          setUser(null);
          setLoading(false);
        });
    } else {
      localStorage.removeItem("token");
      // console.log("no token");
      setUser(null);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (token && !isTokenValid(token)) {
        logout();
      }
    }, 60000); // Check token validity every minute
    return () => clearInterval(interval);
  }, []);

  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      // console.log("valid");
      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  const login = async (info) => {
    await axios.post(`${API_URL}/auth/login`, info).then((response) => {
      localStorage.setItem("token", response.data.result.access_token);
    });
    const token = 'Bearer ' + localStorage.getItem("token");
    // setAuth(token)
    const response = await axios.get(`${API_URL}/users/`, {
      headers: { Authorization: token },
    });
    // console.log(response.data.result);
    setUser(response.data.result);
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 1);
    return response.data.result;
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, auth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
